import { instance } from '@/services/avik-api-gateway'
import { VehicleDefect } from '@/types/vehicle-defect'
import { AxiosResponse } from 'axios'

export interface VehicleDefectDisruptiveHide {
  reason: string
}

export function showVehicleDefectDisruptive(
  defectNumber: number
): Promise<AxiosResponse<VehicleDefect>> {
  return instance.delete(`/hidden-vehicle-defect-disruptive/${defectNumber}`)
}
