
import { defineComponent, ref, provide, computed } from 'vue'
import QTable from 'quasar/src/components/table/QTable.js';
import { useProfile } from '@/composable/useProfile'
import { exportExcel } from '@/services/export-excel'
import { useVehicleDefect } from '@/composable/useVehicleDefect'
import { Exemption } from '@/types/Exemption'
import { format } from 'date-fns'
import OperationalDefectsExemptionModal from './OperationalDefectsExemptionModal.vue'
import { useModal } from '@/composable/useModal'
import OperationalDefectsHideModal from './OperationalDefectsHideModal.vue'
import OperationalDefectsLogModal from './OperationalDefectsLogModal.vue'
import { VehicleDefect } from '@/types/vehicle-defect'
import OperationalDefectsRemoveHideModal from './OperationalDefectsRemoveHideModal.vue'

const acceptedTranslate = {
  ACCEPTED: 'Beviljad',
  REJECTED: 'Nekad',
  PENDING: 'Väntar',
}

export default defineComponent({
  name: 'OperationalDefectsTable',
  components: {
    OperationalDefectsExemptionModal,
    OperationalDefectsHideModal,
    OperationalDefectsLogModal,
    OperationalDefectsRemoveHideModal,
  },
  setup() {
    const {
      fetchDisruptive,
      fetchDisruptiveLog,
      data: vehicleDefects,
      loading,
    } = useVehicleDefect()
    const { can } = useProfile()
    const exemptionModal = useModal()
    const defectHideModal = useModal()
    const defectShowModal = useModal()
    const logModal = useModal()
    provide('exemption-modal', exemptionModal)
    provide('operational-defect-hide-modal', defectHideModal)
    provide('operational-defect-log-modal', logModal)
    provide('operational-defect-show-modal', defectShowModal)
    const exporting = ref(false)
    const showHidden = ref(false)
    fetchDisruptive()
    const actionItems = computed(() => {
      return [
        {
          label: 'Dispens',
          icon: 'mdi-pencil',
          permission:
            can('operationalDefects.exemption.create') ||
            can('operationalDefects.exemption.accept'),
          visible: (row: VehicleDefect) => !row.hidden,
          action: (row: VehicleDefect) => {
            exemptionModal.openModal({ data: row })
          },
        },
        {
          label: 'Dölj',
          icon: 'mdi-eye-off-outline',
          visible: (row: VehicleDefect) => !row.hidden,
          permission: 'operationalDefects.hide',
          action: (row: VehicleDefect) => {
            defectHideModal.openModal({
              data: row,
            })
          },
        },
        {
          label: 'Visa',
          icon: 'mdi-eye-outline',
          visible: (row: VehicleDefect) => row.hidden,
          permission: 'operationalDefects.hide',
          action: (row: VehicleDefect) => {
            defectShowModal.openModal({
              data: row,
            })
          },
        },
        {
          label: 'Logg',
          icon: 'mdi-post-outline',
          visible: () => true,
          permission: 'operationalDefects.log.read',
          action: (row: { defectNumber: number }) => {
            logModal.openModal({
              cb: async (setData) => {
                if (!row.defectNumber) return Promise.resolve()
                const data = await fetchDisruptiveLog(row.defectNumber)
                setData(data)
                return Promise.resolve()
              },
            })
          },
        },
      ].filter((x) => x.permission)
    })
    const columns: QTable['columns'] = [
      {
        align: 'right',
        name: '_actions',
        label: '',
        field: '_actions',
        sortable: false,
      },
      {
        align: 'left',
        name: 'defectNumber',
        label: 'Skadenummer',
        field: 'defectNumber',
        sortable: true,
      },
      {
        align: 'left',
        name: 'creationDateAndTime',
        label: 'Inskrivet',
        field: 'creationDateAndTime',
        format: (x: string) => format(new Date(x), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        align: 'left',
        name: 'vehicleNumber',
        label: 'Fordonsnummer',
        field: 'vehicleNumber',
        sortable: true,
      },
      {
        align: 'left',
        name: 'class',
        label: 'Littera',
        field: 'class',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectCode',
        label: 'Skadekod',
        field: 'defectCode',
        sortable: true,
      },
      {
        align: 'left',
        name: 'class',
        label: 'Skadetext',
        field: (x: VehicleDefect) => x.defectCodeDescription.description,
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectHeading',
        label: 'Rubriktext',
        field: 'defectHeading',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectDescription',
        label: 'Skadebeskrivning',
        field: 'defectDescription',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectRegistrationDepot',
        label: 'Rapporterande plats',
        field: 'defectRegistrationDepot',
        sortable: true,
      },
      {
        align: 'left',
        name: 'exemptions',
        label: 'Dispens status',
        field: 'exemptions',
        format: (value: Exemption[]) =>
          !value.length ? '' : acceptedTranslate[value[0].answeredWith],
        sortable: true,
      },
      {
        align: 'left',
        name: 'hidden',
        label: 'Dold',
        field: 'hidden',
        sortable: true,
      },
    ]
    async function onExport() {
      exporting.value = true
      const exportData = {
        name: 'Driftstörande fel',
        sheets: [
          {
            name: 'Driftstörande fel',
            data: vehicleDefects.value.map((item) => {
              return columns?.reduce<{
                [name: string]: unknown
              }>((acc, col) => {
                const value =
                  typeof col.field === 'function'
                    ? col.field(item)
                    : item[col.name as keyof VehicleDefect]
                if (col.format) {
                  acc[col.label] = col.format(value, null)
                } else {
                  acc[col.label] = value
                }
                return acc
              }, {})
            }),
          },
        ],
      }
      exportExcel(exportData)
      exporting.value = false
    }

    const filteredVehicleDefects = computed(() => {
      return vehicleDefects.value
        .filter((x) => {
          return x.defectCodeDescription?.disruptive
        })
        .filter((x) => {
          return showHidden.value ? true : !x.hidden
        })
    })

    return {
      columns,
      loading,
      vehicleDefects,
      exporting,
      can,
      onExport,
      filterText: ref(''),
      actionItems,
      exemptionModal,
      defectHideModal,
      logModal,
      showHidden,
      filteredVehicleDefects,
      defectShowModal,
    }
  },
})
