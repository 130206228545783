<template>
  <q-card style="max-width: 100%; width: 400px">
    <q-form @submit.prevent="onSubmit" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6"> Visa</q-card-section>

      <q-separator />

      <q-card-section>
        <div class="row">
          <div class="col-12">Vill du ta bort dölj flaggan?</div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="exemptionModal.closeModal"
          type="button"
        />
        <q-btn label="Bekräfta" type="submit" color="primary" />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import { defineComponent, inject, ref } from 'vue'
import { required } from '@/common/formValidationRules'
import { useVehicleDefect } from '@/composable/useVehicleDefect'
import { VehicleDefect } from '@/types/vehicle-defect'

export default defineComponent({
  name: 'OperationalDefectsRemoveHideModal',

  setup() {
    const exemptionModal = inject(
      'operational-defect-show-modal'
    ) as UseModal<VehicleDefect>
    const formRef = ref<QForm | null>(null)
    const { showDisruptive } = useVehicleDefect()

    const model = ref({
      comment: '',
    })

    const onSubmit = async () => {
      if (!exemptionModal.state.data) return
      await showDisruptive(exemptionModal.state.data.defectNumber)
      exemptionModal.closeModal()
    }

    return {
      formRef,
      onSubmit,
      exemptionModal,
      model,
      required,
    }
  },
})
</script>
