import { instance } from '@/services/avik-api-gateway'
import { Exemption } from '@/types/Exemption'
import { AxiosResponse } from 'axios'

export interface VehicleDefectDisruptiveAnswerPost {
  answeredComment: string
  exemptionRequestUuid: string
  answeredWith: 'ACCEPTED' | 'REJECTED'
}

export function createVehicleDefectDisruptiveAnswer(
  defectNumber: number,
  body: VehicleDefectDisruptiveAnswerPost
): Promise<AxiosResponse<Exemption>> {
  return instance.post(
    `/vehicle-defect-disruptive/${defectNumber}/answered-exemption-request`,
    body
  )
}
