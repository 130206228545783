<template>
  <q-card style="max-width: 100%; width: 400px">
    <q-form ref="formRef" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6"> Dispens</q-card-section>

      <q-separator />
      <div class="text-caption q-px-md q-py-xs bg-grey-2">
        Begäran om dispens
      </div>
      <q-separator />

      <q-card-section class="q-pa-none">
        <div class="row">
          <div class="col-12 q-px-md q-pt-md">
            <q-input
              dense
              placeholder="Fritext..."
              type="textarea"
              label="Motivering"
              outlined
              square
              autogrow
              v-model="model.exemption"
              :rules="[required]"
              :readonly="!can('operationalDefects.exemption.create')"
            />
          </div>
        </div>
        <div
          class="row"
          v-if="exemption && exemption.requestedAt && exemption.requestedBy"
        >
          <div class="col-12 q-px-md q-py-xs text-caption text-grey-8">
            {{ formatDate(exemption.requestedAt) }} |
            {{ exemption.requestedBy }}
          </div>
        </div>
      </q-card-section>
      <template v-if="showAcceptedView">
        <q-separator />
        <div class="text-caption q-px-md q-py-xs bg-grey-2">
          Bevilja/neka dispens
        </div>
        <q-separator />

        <q-card-section class="q-pa-none">
          <div class="row">
            <div class="col q-pa-md">
              <q-radio
                dense
                v-model="model.accept"
                val="ACCEPTED"
                label="Bevilja"
                class="q-mr-md"
                :rules="[requiredNotNull]"
                :disable="!can('operationalDefects.exemption.accept')"
              />
              <q-radio
                dense
                v-model="model.accept"
                val="REJECTED"
                color="negative"
                label="Neka"
                :rules="[requiredNotNull]"
                :disable="!can('operationalDefects.exemption.accept')"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 q-px-md q-pt-md">
              <q-input
                dense
                placeholder="Fritext..."
                type="textarea"
                label="Motivering"
                outlined
                square
                autogrow
                v-model="model.acceptComment"
                :rules="[
                  !can('operationalDefects.exemption.accept')
                    ? () => true
                    : required,
                ]"
                :readonly="!can('operationalDefects.exemption.accept')"
              />
            </div>
          </div>
          <div
            class="row"
            v-if="exemption && exemption.answeredAt && exemption.answeredBy"
          >
            <div class="col-12 q-px-md q-py-xs text-caption text-grey-8">
              {{ formatDate(exemption.answeredAt) }} |
              {{ exemption.answeredBy }}
            </div>
          </div>
        </q-card-section>
      </template>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="exemptionModal.closeModal"
          type="button"
          :disable="loading"
        />
        <q-btn
          v-if="can('operationalDefects.exemption.create')"
          label="Skicka begäran"
          @click="createRequest"
          color="primary"
          :loading="loading"
        />
        <q-btn
          v-if="showAcceptedView && can('operationalDefects.exemption.accept')"
          label="Svara på begäran"
          @click="createAnswer"
          color="primary"
          :loading="loading"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import { defineComponent, inject, ref } from 'vue'
import { useProfile } from '@/composable/useProfile'
import { Exemption } from '@/types/Exemption'
import { requiredNotNull, required } from '@/common/formValidationRules'
import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { useVehicleDefect } from '@/composable/useVehicleDefect'

export default defineComponent({
  name: 'OperationalDefectsExemptionModal',

  setup() {
    const exemptionModal = inject('exemption-modal') as UseModal<{
      defectNumber: number
      exemptions: Exemption[]
    }>
    const formRef = ref<QForm | null>(null)
    const { can } = useProfile()
    const {
      updateDisruptiveAnswer,
      createDisruptiveAnswer,
      updateDisruptiveRequest,
      createDisruptiveRequest,
    } = useVehicleDefect()
    const loading = ref(false)

    const exemption = !exemptionModal.state.data?.exemptions.length
      ? null
      : exemptionModal.state.data.exemptions[0]
    const showAcceptedView = !!exemption
    const model = ref({
      exemption: exemption?.requestedComment || '',
      accept: exemption?.answeredWith || null,
      acceptComment: exemption?.answeredComment || '',
    })

    const formatDate = (v: string) => format(new Date(v), 'yyyy-MM-dd HH:mm')

    const createAnswer = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid || !exemptionModal.state.data) return

      if (exemption && !exemption.answeredBy) {
        await createDisruptiveAnswer(exemptionModal.state.data.defectNumber, {
          exemptionRequestUuid: exemption.uuid,
          answeredComment: model.value.acceptComment,
          answeredWith: model.value.accept as 'ACCEPTED' | 'REJECTED',
        })
      } else if (exemption && exemption.answeredBy) {
        await updateDisruptiveAnswer(exemptionModal.state.data.defectNumber, {
          uuid: exemption.uuid,
          answeredComment: model.value.acceptComment,
          answeredWith: model.value.accept as 'ACCEPTED' | 'REJECTED',
        })
      }

      exemptionModal.closeModal()
    }

    const createRequest = async () => {
      const isValid = await formRef.value?.validate()

      if (!isValid || !exemptionModal.state.data) return

      loading.value = true
      if (!exemption) {
        await createDisruptiveRequest(exemptionModal.state.data.defectNumber, {
          uuid: uuidv4(),
          requestedComment: model.value.exemption,
        })
      } else {
        await updateDisruptiveRequest(exemptionModal.state.data.defectNumber, {
          uuid: exemption.uuid,
          requestedComment: model.value.exemption,
        })
      }

      exemptionModal.closeModal()
    }

    return {
      formRef,
      createAnswer,
      createRequest,
      exemptionModal,
      model,
      can,
      requiredNotNull,
      showAcceptedView,
      formatDate,
      exemption,
      loading,
      required,
    }
  },
})
</script>
