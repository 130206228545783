<template>
  <q-page>
    <OperationalDefectsTable />
  </q-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import OperationalDefectsTable from '@/components/tools/operational-defects/OperationalDefectsTable.vue'

export default defineComponent({
  name: 'OperationalDefects',

  components: {
    OperationalDefectsTable,
  },

  setup() {
    return {}
  },
})
</script>
