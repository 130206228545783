import { instance } from '@/services/avik-api-gateway'
import { Exemption } from '@/types/Exemption'
import { AxiosResponse } from 'axios'

export interface VehicleDefectDisruptiveAnswerUpdate {
  uuid: string
  answeredComment: string
  answeredWith: 'ACCEPTED' | 'REJECTED'
}

export function updateVehicleDefectDisruptiveAnswer(
  defectNumber: number,
  body: VehicleDefectDisruptiveAnswerUpdate
): Promise<AxiosResponse<Exemption>> {
  return instance.put(
    `/vehicle-defect-disruptive/${defectNumber}/answered-exemption-request/${body.uuid}`,
    body
  )
}
