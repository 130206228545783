
import { UseModal } from '@/composable/useModal'
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, inject, ref } from 'vue'
import { required } from '@/common/formValidationRules'
import { useVehicleDefect } from '@/composable/useVehicleDefect'
import { VehicleDefect } from '@/types/vehicle-defect'

export default defineComponent({
  name: 'OperationalDefectsRemoveHideModal',

  setup() {
    const exemptionModal = inject(
      'operational-defect-show-modal'
    ) as UseModal<VehicleDefect>
    const formRef = ref<QForm | null>(null)
    const { showDisruptive } = useVehicleDefect()

    const model = ref({
      comment: '',
    })

    const onSubmit = async () => {
      if (!exemptionModal.state.data) return
      await showDisruptive(exemptionModal.state.data.defectNumber)
      exemptionModal.closeModal()
    }

    return {
      formRef,
      onSubmit,
      exemptionModal,
      model,
      required,
    }
  },
})
