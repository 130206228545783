import { Ref, ref, ComputedRef, computed } from 'vue'
import { useLoading, Loading } from '@/composable/useLoading'
import { getVehicleDefectAll } from '@/api/vehicle-defect/getVehicleDefectAll'
import { getVehicleDefectDisruptive } from '@/api/vehicle-defect/getVehicleDefectDisruptive'
import { Log } from '@/types/log'
import { VehicleDefect, VehicleDefectHidden } from '@/types/vehicle-defect'
import {
  updateVehicleDefectDisruptiveAnswer,
  VehicleDefectDisruptiveAnswerUpdate,
} from '@/api/vehicle-defect/updateVehicleDefectDisruptiveAnswer'
import { Exemption } from '@/types/Exemption'
import {
  createVehicleDefectDisruptiveAnswer,
  VehicleDefectDisruptiveAnswerPost,
} from '@/api/vehicle-defect/createVehicleDefectDisruptiveAnswer'
import {
  createVehicleDefectDisruptiveExemption,
  VehicleDefectDisruptiveExemptionPost,
} from '@/api/vehicle-defect/createVehicleDefectDisruptiveExemption'
import {
  updateVehicleDefectDisruptiveExemption,
  VehicleDefectDisruptiveExemptionUpdate,
} from '@/api/vehicle-defect/updateVehicleDefectDisruptiveExemption'
import { hideVehicleDefectDisruptive } from '@/api/vehicle-defect/hideVehicleDefectDisruptive'
import { getLog } from '@/api/log/getLog'
import { showVehicleDefectDisruptive } from '@/api/vehicle-defect/showVehicleDefectDisruptive'

interface UseVehicleDefect {
  data: Ref<VehicleDefect[]>
  fetchAll: () => Promise<VehicleDefect[]>
  fetchDisruptive: () => Promise<VehicleDefect[]>
  fetchDisruptiveLog: (v: number) => Promise<Log[]>
  updateDisruptiveAnswer: (
    v: number,
    d: VehicleDefectDisruptiveAnswerUpdate
  ) => Promise<Exemption>
  createDisruptiveAnswer: (
    v: number,
    d: VehicleDefectDisruptiveAnswerPost
  ) => Promise<Exemption>
  createDisruptiveRequest: (
    v: number,
    d: VehicleDefectDisruptiveExemptionPost
  ) => Promise<Exemption>
  updateDisruptiveRequest: (
    v: number,
    d: VehicleDefectDisruptiveExemptionUpdate
  ) => Promise<Exemption>
  hideDisruptive: (v: VehicleDefectHidden) => Promise<VehicleDefect>
  showDisruptive: (v: number) => Promise<VehicleDefect>
  loading: ComputedRef<Loading>
}

const data = ref<VehicleDefect[]>([])
const log = ref<Log[]>([])
const { state } = useLoading()

export function useVehicleDefect(): UseVehicleDefect {
  async function fetchAll() {
    state.getAll = true
    return new Promise<VehicleDefect[]>((resolve, reject) => {
      getVehicleDefectAll()
        .then(({ data: vehicleDefects }) => {
          data.value = vehicleDefects
          resolve(vehicleDefects)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function fetchDisruptive() {
    state.getAll = true
    return new Promise<VehicleDefect[]>((resolve, reject) => {
      getVehicleDefectDisruptive()
        .then(({ data: vehicleDefects }) => {
          data.value = vehicleDefects
          resolve(vehicleDefects)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function fetchDisruptiveLog(defectNumber: number) {
    state.getAll = true
    return new Promise<Log[]>((resolve, reject) => {
      getLog(defectNumber)
        .then(({ data: logData }) => {
          log.value = logData
          resolve(logData)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function createDisruptiveAnswer(
    defectNumber: number,
    payload: VehicleDefectDisruptiveAnswerPost
  ) {
    state.getAll = true
    return new Promise<Exemption>((resolve, reject) => {
      createVehicleDefectDisruptiveAnswer(defectNumber, payload)
        .then(({ data: newData }) => {
          const foundItem = data.value.find(
            (x) => x.defectNumber === defectNumber
          )
          if (foundItem) {
            foundItem.exemptions = foundItem.exemptions.filter(
              (x) => x.uuid !== payload.exemptionRequestUuid
            )
            foundItem.exemptions.push(newData)
          }

          resolve(newData)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function updateDisruptiveAnswer(
    defectNumber: number,
    payload: VehicleDefectDisruptiveAnswerUpdate
  ) {
    state.getAll = true
    return new Promise<Exemption>((resolve, reject) => {
      updateVehicleDefectDisruptiveAnswer(defectNumber, payload)
        .then(({ data: newData }) => {
          const foundItem = data.value.find(
            (x) => x.defectNumber === defectNumber
          )
          if (foundItem) {
            foundItem.exemptions = foundItem.exemptions.filter(
              (x) => x.uuid !== payload.uuid
            )
            foundItem.exemptions.push(newData)
          }

          resolve(newData)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function createDisruptiveRequest(
    defectNumber: number,
    payload: VehicleDefectDisruptiveExemptionPost
  ) {
    state.getAll = true
    return new Promise<Exemption>((resolve, reject) => {
      createVehicleDefectDisruptiveExemption(defectNumber, payload)
        .then(({ data: newData }) => {
          const foundItem = data.value.find(
            (x) => x.defectNumber === defectNumber
          )
          if (foundItem) {
            foundItem.exemptions = foundItem.exemptions.filter(
              (x) => x.uuid !== payload.uuid
            )
            foundItem.exemptions.push(newData)
          }

          resolve(newData)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function updateDisruptiveRequest(
    defectNumber: number,
    payload: VehicleDefectDisruptiveExemptionUpdate
  ) {
    state.getAll = true
    return new Promise<Exemption>((resolve, reject) => {
      updateVehicleDefectDisruptiveExemption(defectNumber, payload)
        .then(({ data: newData }) => {
          const foundItem = data.value.find(
            (x) => x.defectNumber === defectNumber
          )
          if (foundItem) {
            foundItem.exemptions = foundItem.exemptions.filter(
              (x) => x.uuid !== payload.uuid
            )
            foundItem.exemptions.push(newData)
          }

          resolve(newData)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function hideDisruptive(payload: VehicleDefectHidden) {
    state.getAll = true
    return new Promise<VehicleDefect>((resolve, reject) => {
      hideVehicleDefectDisruptive(payload)
        .then(({ data: newData }) => {
          const foundItem = data.value.find(
            (x) => x.defectNumber === payload.defectNumber
          )
          if (foundItem) {
            foundItem.hidden = payload
          }

          resolve(newData)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  async function showDisruptive(defectNumber: number) {
    state.getAll = true
    return new Promise<VehicleDefect>((resolve, reject) => {
      showVehicleDefectDisruptive(defectNumber)
        .then(({ data: newData }) => {
          const foundItem = data.value.find(
            (x) => x.defectNumber === defectNumber
          )
          if (foundItem) {
            foundItem.hidden = null
          }

          resolve(newData)
        })
        .catch(reject)
        .finally(() => {
          state.getAll = false
        })
    })
  }

  return {
    data,
    fetchAll,
    fetchDisruptive,
    fetchDisruptiveLog,
    updateDisruptiveAnswer,
    createDisruptiveAnswer,
    createDisruptiveRequest,
    updateDisruptiveRequest,
    hideDisruptive,
    showDisruptive,
    loading: computed(() => state),
  }
}
