<template>
  <q-dialog v-model="exemptionModal.state.show" :persistent="false">
    <OperationalDefectsExemptionModal v-if="exemptionModal.state.show" />
  </q-dialog>
  <q-dialog v-model="defectHideModal.state.show" :persistent="false">
    <OperationalDefectsHideModal v-if="defectHideModal.state.show" />
  </q-dialog>
  <q-dialog v-model="logModal.state.show" :persistent="false">
    <OperationalDefectsLogModal v-if="logModal.state.show" />
  </q-dialog>
  <q-dialog v-model="defectShowModal.state.show" :persistent="false">
    <OperationalDefectsRemoveHideModal v-if="defectShowModal.state.show" />
  </q-dialog>
  <q-table
    dense
    :class="$style.stickyHeader"
    :loading="loading.getAll"
    :filter="filterText"
    :rows-per-page-options="[0]"
    :rows="filteredVehicleDefects"
    :columns="columns.filter((x) => (showHidden ? true : x.name !== 'hidden'))"
    row-key="defectNumber"
    virtual-scroll
    style="height: calc(100vh - 50px - 68px)"
    flat
  >
    <template v-slot:body-cell-defectDescription="props">
      <q-td :props="props">
        <div style="white-space: pre-wrap">
          {{ props.value }}
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-defectHeading="props">
      <q-td :props="props">
        <div style="white-space: pre-wrap">
          {{ props.value }}
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-exemptions="props">
      <q-td :props="props">
        <div style="white-space: pre-wrap">
          {{ props.value }}
        </div>
      </q-td>
    </template>

    <template v-slot:body-cell-hidden="props">
      <q-td :props="props">
        <q-icon
          v-if="props.row.hidden"
          name="mdi-eye-off-outline"
          class="q-mr-sm"
        />
      </q-td>
    </template>

    <template v-slot:body-cell-_actions="props">
      <q-td :props="props">
        <q-btn-dropdown
          size="sm"
          flat
          round
          color="grey-7"
          dropdown-icon="mdi-dots-vertical"
          content-class="no-border-radius"
        >
          <q-list>
            <q-item
              clickable
              v-close-popup
              @click="actionItem.action(props.row)"
              v-for="actionItem in actionItems.filter((x) =>
                x.visible(props.row)
              )"
              :key="actionItem.label"
            >
              <q-item-section avatar>
                <q-icon size="xs" :name="actionItem.icon" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ actionItem.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </q-td>
    </template>

    <template v-slot:top>
      <h6 class="q-ma-none">Driftstörande fel</h6>

      <q-space />

      <q-input
        style="width: 100%; max-width: 300px"
        debounce="300"
        v-model="filterText"
        placeholder="Sök..."
        dense
        outlined
      >
        <template v-slot:append>
          <q-icon name="mdi-magnify" />
        </template>
      </q-input>
      <q-btn
        @click="() => (showHidden = !showHidden)"
        class="q-ml-md"
        :label="showHidden ? 'Dölj dolda' : 'Visa dolda'"
        color="grey-7"
        outline
      />
      <q-btn
        :loading="exporting"
        @click="onExport"
        class="q-ml-md"
        icon-right="mdi-file-export-outline"
        label="Export"
        color="grey-7"
        outline
      />
    </template>
  </q-table>
</template>

<script lang="ts">
import { defineComponent, ref, provide, computed } from 'vue'
import { QTable } from 'quasar'
import { useProfile } from '@/composable/useProfile'
import { exportExcel } from '@/services/export-excel'
import { useVehicleDefect } from '@/composable/useVehicleDefect'
import { Exemption } from '@/types/Exemption'
import { format } from 'date-fns'
import OperationalDefectsExemptionModal from './OperationalDefectsExemptionModal.vue'
import { useModal } from '@/composable/useModal'
import OperationalDefectsHideModal from './OperationalDefectsHideModal.vue'
import OperationalDefectsLogModal from './OperationalDefectsLogModal.vue'
import { VehicleDefect } from '@/types/vehicle-defect'
import OperationalDefectsRemoveHideModal from './OperationalDefectsRemoveHideModal.vue'

const acceptedTranslate = {
  ACCEPTED: 'Beviljad',
  REJECTED: 'Nekad',
  PENDING: 'Väntar',
}

export default defineComponent({
  name: 'OperationalDefectsTable',
  components: {
    OperationalDefectsExemptionModal,
    OperationalDefectsHideModal,
    OperationalDefectsLogModal,
    OperationalDefectsRemoveHideModal,
  },
  setup() {
    const {
      fetchDisruptive,
      fetchDisruptiveLog,
      data: vehicleDefects,
      loading,
    } = useVehicleDefect()
    const { can } = useProfile()
    const exemptionModal = useModal()
    const defectHideModal = useModal()
    const defectShowModal = useModal()
    const logModal = useModal()
    provide('exemption-modal', exemptionModal)
    provide('operational-defect-hide-modal', defectHideModal)
    provide('operational-defect-log-modal', logModal)
    provide('operational-defect-show-modal', defectShowModal)
    const exporting = ref(false)
    const showHidden = ref(false)
    fetchDisruptive()
    const actionItems = computed(() => {
      return [
        {
          label: 'Dispens',
          icon: 'mdi-pencil',
          permission:
            can('operationalDefects.exemption.create') ||
            can('operationalDefects.exemption.accept'),
          visible: (row: VehicleDefect) => !row.hidden,
          action: (row: VehicleDefect) => {
            exemptionModal.openModal({ data: row })
          },
        },
        {
          label: 'Dölj',
          icon: 'mdi-eye-off-outline',
          visible: (row: VehicleDefect) => !row.hidden,
          permission: 'operationalDefects.hide',
          action: (row: VehicleDefect) => {
            defectHideModal.openModal({
              data: row,
            })
          },
        },
        {
          label: 'Visa',
          icon: 'mdi-eye-outline',
          visible: (row: VehicleDefect) => row.hidden,
          permission: 'operationalDefects.hide',
          action: (row: VehicleDefect) => {
            defectShowModal.openModal({
              data: row,
            })
          },
        },
        {
          label: 'Logg',
          icon: 'mdi-post-outline',
          visible: () => true,
          permission: 'operationalDefects.log.read',
          action: (row: { defectNumber: number }) => {
            logModal.openModal({
              cb: async (setData) => {
                if (!row.defectNumber) return Promise.resolve()
                const data = await fetchDisruptiveLog(row.defectNumber)
                setData(data)
                return Promise.resolve()
              },
            })
          },
        },
      ].filter((x) => x.permission)
    })
    const columns: QTable['columns'] = [
      {
        align: 'right',
        name: '_actions',
        label: '',
        field: '_actions',
        sortable: false,
      },
      {
        align: 'left',
        name: 'defectNumber',
        label: 'Skadenummer',
        field: 'defectNumber',
        sortable: true,
      },
      {
        align: 'left',
        name: 'creationDateAndTime',
        label: 'Inskrivet',
        field: 'creationDateAndTime',
        format: (x: string) => format(new Date(x), 'yyyy-MM-dd HH:mm'),
        sortable: true,
      },
      {
        align: 'left',
        name: 'vehicleNumber',
        label: 'Fordonsnummer',
        field: 'vehicleNumber',
        sortable: true,
      },
      {
        align: 'left',
        name: 'class',
        label: 'Littera',
        field: 'class',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectCode',
        label: 'Skadekod',
        field: 'defectCode',
        sortable: true,
      },
      {
        align: 'left',
        name: 'class',
        label: 'Skadetext',
        field: (x: VehicleDefect) => x.defectCodeDescription.description,
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectHeading',
        label: 'Rubriktext',
        field: 'defectHeading',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectDescription',
        label: 'Skadebeskrivning',
        field: 'defectDescription',
        sortable: true,
      },
      {
        align: 'left',
        name: 'defectRegistrationDepot',
        label: 'Rapporterande plats',
        field: 'defectRegistrationDepot',
        sortable: true,
      },
      {
        align: 'left',
        name: 'exemptions',
        label: 'Dispens status',
        field: 'exemptions',
        format: (value: Exemption[]) =>
          !value.length ? '' : acceptedTranslate[value[0].answeredWith],
        sortable: true,
      },
      {
        align: 'left',
        name: 'hidden',
        label: 'Dold',
        field: 'hidden',
        sortable: true,
      },
    ]
    async function onExport() {
      exporting.value = true
      const exportData = {
        name: 'Driftstörande fel',
        sheets: [
          {
            name: 'Driftstörande fel',
            data: vehicleDefects.value.map((item) => {
              return columns?.reduce<{
                [name: string]: unknown
              }>((acc, col) => {
                const value =
                  typeof col.field === 'function'
                    ? col.field(item)
                    : item[col.name as keyof VehicleDefect]
                if (col.format) {
                  acc[col.label] = col.format(value, null)
                } else {
                  acc[col.label] = value
                }
                return acc
              }, {})
            }),
          },
        ],
      }
      exportExcel(exportData)
      exporting.value = false
    }

    const filteredVehicleDefects = computed(() => {
      return vehicleDefects.value
        .filter((x) => {
          return x.defectCodeDescription?.disruptive
        })
        .filter((x) => {
          return showHidden.value ? true : !x.hidden
        })
    })

    return {
      columns,
      loading,
      vehicleDefects,
      exporting,
      can,
      onExport,
      filterText: ref(''),
      actionItems,
      exemptionModal,
      defectHideModal,
      logModal,
      showHidden,
      filteredVehicleDefects,
      defectShowModal,
    }
  },
})
</script>

<style lang="scss" module>
.stickyHeader {
  .q-table__top,
  .q-table__bottom,
  thead tr:first-child th {
    background-color: white;
  }

  thead tr th {
    position: sticky;
    z-index: 1;
  }

  thead tr:first-child th {
    top: 0;
  }
}
</style>
