<template>
  <q-card style="min-width: 900px">
    <q-card-section class="row items-center text-h6"> Logg </q-card-section>
    <q-card-section class="q-pa-none">
      <q-table
        flat
        hide-bottom
        dense
        sort
        :rows-per-page-options="[0]"
        :rows="sortedLog"
        :columns="columns"
      >
        <template v-slot:body-cell-was="props">
          <q-td :props="props">
            <pre style="white-space: pre-wrap">{{ props.value }}</pre>
          </q-td>
        </template>
        <template v-slot:body-cell-change="props">
          <q-td :props="props">
            <pre style="white-space: pre-wrap">{{ props.value }}</pre>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
    <q-separator />
    <q-card-actions align="right">
      <q-btn flat label="Stäng" @click="logModal.closeModal" type="button" />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { Log } from '@/types/log'
import { format } from 'date-fns'
import { defineComponent, inject } from 'vue'
import orderBy from 'lodash.orderby'

export default defineComponent({
  name: 'OperationalDefectsLogModal',

  setup() {
    const logModal = inject('operational-defect-log-modal') as UseModal

    const columns = [
      {
        align: 'left',
        name: 'method',
        required: true,
        label: 'Typ',
        field: 'method',
        sortable: false,
      },
      {
        align: 'left',
        name: 'created',
        required: true,
        label: 'Loggdatum',
        format: (v: string) => format(new Date(v), 'yyyy-MM-dd HH:mm'),
        field: 'created',
        sortable: false,
      },
      {
        align: 'left',
        name: 'user',
        required: true,
        label: 'Användare',
        field: (v: Log) => v?.user?.email,
        sortable: false,
      },
      {
        align: 'left',
        name: 'was',
        required: true,
        label: 'Innan ändring',
        field: (v: Log) => JSON.stringify(v?.change?.before, null, 2),
        sortable: false,
      },
      {
        align: 'left',
        name: 'change',
        required: true,
        label: 'Efter ändring',
        field: (v: Log) => JSON.stringify(v?.change?.after, null, 2),
        sortable: false,
      },
    ]

    const sortedLog = orderBy(logModal?.state.data || [], ['created'], ['desc'])

    return { logModal, sortedLog, columns, orderBy }
  },
})
</script>
