
import { UseModal } from '@/composable/useModal'
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, inject, ref } from 'vue'
import { required } from '@/common/formValidationRules'
import { useVehicleDefect } from '@/composable/useVehicleDefect'
import { VehicleDefect } from '@/types/vehicle-defect'

export default defineComponent({
  name: 'OperationalDefectsHideModal',

  setup() {
    const exemptionModal = inject(
      'operational-defect-hide-modal'
    ) as UseModal<VehicleDefect>
    const formRef = ref<QForm | null>(null)
    const { hideDisruptive } = useVehicleDefect()

    const model = ref({
      comment: '',
    })

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      console.log(isValid)
      console.log(exemptionModal.state.data)
      if (!isValid || !exemptionModal.state.data) return
      await hideDisruptive({
        defectNumber: exemptionModal.state.data.defectNumber,
        reason: model.value.comment,
      })
      exemptionModal.closeModal()
    }

    return {
      formRef,
      onSubmit,
      exemptionModal,
      model,
      required,
    }
  },
})
