<template>
  <q-card style="max-width: 100%; width: 400px">
    <q-form ref="formRef" @submit.prevent="onSubmit" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6"> Dölj</q-card-section>

      <q-separator />

      <q-card-section>
        <div class="row">
          <div class="col-12">
            <q-input
              placeholder="Fritext..."
              type="textarea"
              label="Orsak"
              outlined
              square
              :rules="[required]"
              autogrow
              v-model="model.comment"
            />
          </div>
        </div>
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="exemptionModal.closeModal"
          type="button"
        />
        <q-btn label="Dölj" type="submit" color="primary" />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { UseModal } from '@/composable/useModal'
import { QForm } from 'quasar'
import { defineComponent, inject, ref } from 'vue'
import { required } from '@/common/formValidationRules'
import { useVehicleDefect } from '@/composable/useVehicleDefect'
import { VehicleDefect } from '@/types/vehicle-defect'

export default defineComponent({
  name: 'OperationalDefectsHideModal',

  setup() {
    const exemptionModal = inject(
      'operational-defect-hide-modal'
    ) as UseModal<VehicleDefect>
    const formRef = ref<QForm | null>(null)
    const { hideDisruptive } = useVehicleDefect()

    const model = ref({
      comment: '',
    })

    const onSubmit = async () => {
      const isValid = await formRef.value?.validate()
      console.log(isValid)
      console.log(exemptionModal.state.data)
      if (!isValid || !exemptionModal.state.data) return
      await hideDisruptive({
        defectNumber: exemptionModal.state.data.defectNumber,
        reason: model.value.comment,
      })
      exemptionModal.closeModal()
    }

    return {
      formRef,
      onSubmit,
      exemptionModal,
      model,
      required,
    }
  },
})
</script>
