import { instance } from '@/services/avik-api-gateway'
import { Exemption } from '@/types/Exemption'
import { AxiosResponse } from 'axios'

export interface VehicleDefectDisruptiveExemptionPost {
  uuid: string
  requestedComment: string
}

export function createVehicleDefectDisruptiveExemption(
  defectNumber: number,
  body: VehicleDefectDisruptiveExemptionPost
): Promise<AxiosResponse<Exemption>> {
  return instance.post(
    `/vehicle-defect-disruptive/${defectNumber}/exemption-request`,
    body
  )
}
