import { instance } from '@/services/avik-api-gateway'
import { VehicleDefect } from '@/types/vehicle-defect'
import { AxiosResponse } from 'axios'

export interface VehicleDefectDisruptiveHide {
  defectNumber: number
  reason: string
}

export function hideVehicleDefectDisruptive(
  body: VehicleDefectDisruptiveHide
): Promise<AxiosResponse<VehicleDefect>> {
  return instance.post(`/hidden-vehicle-defect-disruptive`, body)
}
