import { instance } from '@/services/avik-api-gateway'
import { Exemption } from '@/types/Exemption'
import { AxiosResponse } from 'axios'

export interface VehicleDefectDisruptiveExemptionUpdate {
  uuid: string
  requestedComment: string
}

export function updateVehicleDefectDisruptiveExemption(
  defectNumber: number,
  body: VehicleDefectDisruptiveExemptionUpdate
): Promise<AxiosResponse<Exemption>> {
  return instance.put(
    `/vehicle-defect-disruptive/${defectNumber}/exemption-request/${body.uuid}`,
    body
  )
}
