
import { UseModal } from '@/composable/useModal'
import QForm from 'quasar/src/components/form/QForm.js';
import { defineComponent, inject, ref } from 'vue'
import { useProfile } from '@/composable/useProfile'
import { Exemption } from '@/types/Exemption'
import { requiredNotNull, required } from '@/common/formValidationRules'
import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import { useVehicleDefect } from '@/composable/useVehicleDefect'

export default defineComponent({
  name: 'OperationalDefectsExemptionModal',

  setup() {
    const exemptionModal = inject('exemption-modal') as UseModal<{
      defectNumber: number
      exemptions: Exemption[]
    }>
    const formRef = ref<QForm | null>(null)
    const { can } = useProfile()
    const {
      updateDisruptiveAnswer,
      createDisruptiveAnswer,
      updateDisruptiveRequest,
      createDisruptiveRequest,
    } = useVehicleDefect()
    const loading = ref(false)

    const exemption = !exemptionModal.state.data?.exemptions.length
      ? null
      : exemptionModal.state.data.exemptions[0]
    const showAcceptedView = !!exemption
    const model = ref({
      exemption: exemption?.requestedComment || '',
      accept: exemption?.answeredWith || null,
      acceptComment: exemption?.answeredComment || '',
    })

    const formatDate = (v: string) => format(new Date(v), 'yyyy-MM-dd HH:mm')

    const createAnswer = async () => {
      const isValid = await formRef.value?.validate()
      if (!isValid || !exemptionModal.state.data) return

      if (exemption && !exemption.answeredBy) {
        await createDisruptiveAnswer(exemptionModal.state.data.defectNumber, {
          exemptionRequestUuid: exemption.uuid,
          answeredComment: model.value.acceptComment,
          answeredWith: model.value.accept as 'ACCEPTED' | 'REJECTED',
        })
      } else if (exemption && exemption.answeredBy) {
        await updateDisruptiveAnswer(exemptionModal.state.data.defectNumber, {
          uuid: exemption.uuid,
          answeredComment: model.value.acceptComment,
          answeredWith: model.value.accept as 'ACCEPTED' | 'REJECTED',
        })
      }

      exemptionModal.closeModal()
    }

    const createRequest = async () => {
      const isValid = await formRef.value?.validate()

      if (!isValid || !exemptionModal.state.data) return

      loading.value = true
      if (!exemption) {
        await createDisruptiveRequest(exemptionModal.state.data.defectNumber, {
          uuid: uuidv4(),
          requestedComment: model.value.exemption,
        })
      } else {
        await updateDisruptiveRequest(exemptionModal.state.data.defectNumber, {
          uuid: exemption.uuid,
          requestedComment: model.value.exemption,
        })
      }

      exemptionModal.closeModal()
    }

    return {
      formRef,
      createAnswer,
      createRequest,
      exemptionModal,
      model,
      can,
      requiredNotNull,
      showAcceptedView,
      formatDate,
      exemption,
      loading,
      required,
    }
  },
})
